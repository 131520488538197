<template>
  <a-row :gutter="10">
    <a-col :span="5">
      <vehicle @success="selectLog"/>
    </a-col>
    <a-col :span="19">
      <List ref="list"/>
    </a-col>
  </a-row>
</template>
<script>
import List from './List.vue'
import vehicle from './vehicle.vue'

export default {
  components: {
    List,
    vehicle
  },
  data() {
    return {}
  },
  methods: {
    selectLog(vehicle, vehicleIds,type) {
      this.$refs.list.setVehicle(vehicle, vehicleIds,type)
    },
  },
}

</script>
