<template>
  <a-card :bordered="false">
    <div class="con">
      <div class="head">
        <div class="card-title">选择对比车辆</div>
        <a-input-search
            allowClear
            v-model="queryParam.keyword"
            placeholder="请输入车牌号码"
            @search="onSearch"
        />
      </div>
      <a-radio-group v-model="type" style="margin-top: 10px" @change="rendType">
        <a-radio :value="0">
          公司运营
        </a-radio>
        <a-radio :value="1">
          指定车辆分析
        </a-radio>
      </a-radio-group>

      <a-checkbox v-model="allVehicle" v-if="type===1" @change="onCheckboxVehicle">
        全选车辆
      </a-checkbox>

      <div class="main" style="margin-top: 10px">
        <a-spin :spinning="confirmLoading" v-if="type===1">
          <div class="list" v-if="list.length">
            <div
                :class="['list-item']" v-for="item in list">
              <a-checkbox v-model="item.check" @change="setActionVehicle(item)">{{ item.vehicle_name }}</a-checkbox>
            </div>
          </div>
          <a-empty v-else/>
        </a-spin>
      </div>
    </div>

  </a-card>
</template>
<script>
import * as Api from '@/api/vehicle'

export default {
  components: {},
  data() {
    return {
      queryParam: {
        keyword: ''
      },
      list: [],
      actionVehicle: [],
      actionVehicleIds: [],
      confirmLoading: false,
      type: 0,
      allVehicle:false
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      this.confirmLoading = true
      const {data: {list}} = await Api.enableList(this.queryParam)
      this.list = list.map(item => {
        item.check = false
        return item
      })
      this.confirmLoading = false
    },
    onSearch(e) {
      this.actionVehicle = []
      this.actionVehicleIds = []
      this.getList()
    },

    rendType() {
      if (this.type === 0) {
        this.list = this.list.map(item => {
          item.check = false
          return item
        })
      }
      this.actionVehicleIds = []
      this.actionVehicle = []
      this.$emit('success', this.actionVehicle, this.actionVehicleIds, this.type)
    },

    setActionVehicle() {
      var ids = []
      var vm = []
      this.list.forEach(item => {
        if (item.check) {
          ids.push(item.vehicle_id)
          vm.push(item)
        }
      })
      this.actionVehicleIds = ids
      this.actionVehicle = vm
      this.$emit('success', this.actionVehicle, this.actionVehicleIds, this.type)
    },

    onCheckboxVehicle(e){
      console.log(e.target.checked)
      this.list.forEach(item=>{
        item.check=true
      })

      this.$nextTick(()=>{
        this.setActionVehicle()
      })
    }
  },
}

</script>
<style scoped lang="less">
.con {
  height: calc(100vh - 140px);
  background: #FFFFFF;
  flex-direction: column;
  display: flex;
}

.head {
  flex-grow: 1;
}

.main {
  height: 100%;
  flex-grow: 1;
  overflow: scroll;
}

.list {
  border: 1px solid #e8e8e8;

  .list-item {
    font-weight: bold;
    border-bottom: 1px solid #e8e8e8;
    padding: 8px;
    cursor: pointer;
    transition: all .3s;

    &:hover {
      background: #f4f4f4;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .action {
    background: #378DDB !important;
    color: #FFFFFF;
  }
}
</style>
